import { useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';

import MerchantForm from 'components/MerchantForm';
import {
  createMerchant,
  modifyMerchant,
  fetchMerchants,
} from 'state/actions/merchants';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

const schema = yup.object().shape({
  name: yup.string().required(),
  active: yup.boolean().notRequired(),
  url: yup.string().required(),
  secret: yup.string().required(),
  createdAt: yup.string().required(),
});

const Merchant = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, merchantData, error } = useSelector(
    (state) => ({
      success: state.merchants.success,
      merchantData: isEditing
        ? state.merchants.data.find((merchant) => merchant.id === id)
        : undefined,
      error: state.merchants.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!merchantData) {
        dispatch(fetchMerchants(id));
      }
    }
  }, [isEditing, id, merchantData]);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.MERCHANTS} />
  );

  const editMerchantMessage = useFormatMessage('Merchant.editMerchant');

  const newMerchantMessage = useFormatMessage('Merchants.newMerchant');

  const onSubmitHandler = (value) => {
    const newMerchant = {
      ...value,
      file: value?.file[0] || null,
      isEditing,
      id,
    };

    if (isEditing) {
      dispatch(modifyMerchant(newMerchant));
    } else {
      dispatch(createMerchant(newMerchant));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editMerchantMessage : newMerchantMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !merchantData ? (
          <ClipLoader />
        ) : (
          <MerchantForm
            isEditing={isEditing}
            merchant={
              isEditing
                ? merchantData
                : {
                    name: '',
                    url: '',
                    secret: '',
                    createdAt: new Date().toDateString(),
                    active: false,
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Merchant;
