import { NEW_PATH } from 'constants/index';

export default {
  ROOT: '/',
  LOGIN: '/login',
  USERS: '/users',
  ADD_USER: `/users/${NEW_PATH}`,
  MODIFY_USER: '/users/:id',
  PROFILE: '/profile',
  RESET_PASSWORD: '/recover-password',
  SECTION: '/section',
  SUBMENU_1: '/submenu1',
  SUBMENU_2: '/submenu2',
  MERCHANTS: '/merchants',
  METRICS: '/metrics',
  LOGS: '/logs',
  CONFIGURATION: '/config',
  ADD_MERCHANT: `/merchants/${NEW_PATH}`,
  APPLICATIONS: '/merchants/:id/applications',
  ADD_APPLICATION: `/merchants/:id/applications/${NEW_PATH}`,
  MODIFY_MERCHANT: '/merchants/:id',
  MODIFY_APPLICATION: '/merchants/:id/applications/:appId',
};
