import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatDate, useFormatMessage } from 'hooks';
import Table from 'components/Table';
import { fetchLogs } from 'state/actions/logs';
import paths from 'pages/Router/paths';
import classes from './Logs.module.scss';

const Logs = () => {
  const { logsList, isAdmin, error, loading } = useSelector(
    (state) => ({
      logsList: state.logs.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.logs.error,
      loading: state.logs.loading,
      deleted: state.logs.deleted,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchLogs());
    }
  }, [isAdmin]);

  const redirect = !isAdmin && <Redirect to={paths.ROOT} />;

  const columns = [
    {
      Header: useFormatMessage('Logs.dateTime'),
      accessor: 'dateTime',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.dateTime, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: useFormatMessage('Logs.merchant'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('Logs.error'),
      accessor: 'error',
      Cell: ({ row }) => (
        <small className="is-abbr-like">{row.original.error}</small>
      ),
    },
  ];

  const data = useMemo(
    () =>
      search
        ? logsList.filter((el) => {
            const clonedElem = { ...el };
            delete clonedElem.id;
            delete clonedElem.isAdmin;
            delete clonedElem.logoUrl;
            return Object.values(clonedElem).some((field) =>
              field
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase())
            );
          })
        : logsList,
    [search, logsList]
  );

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">{useFormatMessage('Logs.logs')}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Merchants.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading && data.lenght > 0 ? (
              <ClipLoader />
            ) : (
              <Table columns={columns} data={data} />
            )}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
};

export default Logs;
