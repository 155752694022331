import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';

import { fetchCollection, deleteDocument, updateDocument } from 'state/api';
import {
  createMerchantWithChecks,
  deleteLogo,
  uploadLogo,
  getLogoUrl,
} from 'services/merchantsServices';
import {
  MERCHANTS_CLEAN_UP,
  MERCHANTS_FETCH_DATA_INIT,
  MERCHANTS_FETCH_DATA_SUCCESS,
  MERCHANTS_FETCH_DATA_FAIL,
  MERCHANTS_CLEAR_DATA_LOGOUT,
  MERCHANTS_CREATE_MERCHANT_FAIL,
  MERCHANTS_CREATE_MERCHANT_INIT,
  MERCHANTS_CREATE_MERCHANT_SUCCESS,
  MERCHANTS_DELETE_MERCHANT_FAIL,
  MERCHANTS_DELETE_MERCHANT_INIT,
  MERCHANTS_DELETE_MERCHANT_SUCCESS,
  MERCHANTS_MODIFY_MERCHANT_FAIL,
  MERCHANTS_MODIFY_MERCHANT_INIT,
  MERCHANTS_MODIFY_MERCHANT_SUCCESS,
} from 'state/actionCreators/merchants';

export const fetchMerchants = () => async (dispatch) => {
  dispatch(MERCHANTS_FETCH_DATA_INIT());

  try {
    const merchants = await fetchCollection('merchants');

    return dispatch(
      MERCHANTS_FETCH_DATA_SUCCESS({
        data: merchants,
      })
    );
  } catch (error) {
    toastr.error('Error fetching merchants', error);
    return dispatch(MERCHANTS_FETCH_DATA_FAIL({ error }));
  }
};

export const deleteMerchant = (id) => {
  return async (dispatch, getState) => {
    dispatch(MERCHANTS_DELETE_MERCHANT_INIT());
    const { locale } = getState().preferences;
    const { logoUrl } = getState()
      .merchants.data.filter((merchant) => merchant.id === id)
      .pop();

    const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;

    const deleteMerchantTask = deleteDocument('merchants', id);

    try {
      await Promise.all([deleteLogoTask, deleteMerchantTask]);
    } catch (error) {
      const errorMessage = firebaseError(error, locale);
      toastr.error('', errorMessage);
      return dispatch(
        MERCHANTS_DELETE_MERCHANT_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The merchant was deleted.');
    return dispatch(MERCHANTS_DELETE_MERCHANT_SUCCESS({ id }));
  };
};

export const clearMerchantsDataLogout = () => MERCHANTS_CLEAR_DATA_LOGOUT();

export const createMerchant = ({
  name,
  url,
  secret,
  active,
  createdAt,
  file,
}) => {
  return async (dispatch, getState) => {
    dispatch(MERCHANTS_CREATE_MERCHANT_INIT());
    const { locale } = getState().preferences;

    let merchantData;

    try {
      merchantData = await createMerchantWithChecks({
        name,
        url,
        secret,
        active,
        createdAt,
        file,
      });
    } catch (error) {
      const errorMessage = firebaseError(error, locale);
      toastr.error('', errorMessage);
      return dispatch(
        MERCHANTS_CREATE_MERCHANT_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Merchant created successfully');
    return dispatch(
      MERCHANTS_CREATE_MERCHANT_SUCCESS({ merchant: merchantData })
    );
  };
};

export const modifyMerchant = ({
  url,
  secret,
  active,
  file,
  createdAt,
  id,
  isEditing,
}) => {
  return async (dispatch, getState) => {
    dispatch(MERCHANTS_MODIFY_MERCHANT_INIT());
    const { locale } = getState().preferences;
    const merchant = getState().merchants.data.find(
      (thisMerchant) => thisMerchant.id === id
    );
    let { logoUrl } = merchant;
    let deleteLogoTask;
    let uploadLogoTask;
    let newLogoUrl = null;
    if (file) {
      newLogoUrl = getLogoUrl(id, file);
      deleteLogoTask = logoUrl && (await deleteLogo(logoUrl));
      uploadLogoTask = await uploadLogo(id, file);
      logoUrl = newLogoUrl;
    }

    const merchantData = {
      url,
      secret,
      createdAt,
      active,
      logoUrl,
    };
    const updateMerchantDbTask = updateDocument('merchants', id, merchantData);

    try {
      await Promise.all([deleteLogoTask, uploadLogoTask, updateMerchantDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error, locale);
      toastr.error('', errorMessage);
      return dispatch(
        MERCHANTS_MODIFY_MERCHANT_FAIL({
          error: errorMessage,
        })
      );
    }

    if (isEditing) {
      toastr.success('', 'Merchant updated successfully');
    }

    return dispatch(
      MERCHANTS_MODIFY_MERCHANT_SUCCESS({ merchant: merchantData, id })
    );
  };
};

export const merchantsCleanUp = () => MERCHANTS_CLEAN_UP();
