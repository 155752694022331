import { useEffect, useMemo, useCallback } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import ClipLoader from 'components/Common/clipLoader';

import ApplicationForm from 'components/ApplicationForm';
import {
  createApplication,
  fetchApplications,
  modifyApplication,
} from 'state/actions/applications';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';
import { NEW_PATH } from 'constants/index';

const Application = () => {
  const { id } = useParams();
  const { appId } = useParams();

  const isEditing = useMemo(() => appId !== NEW_PATH, [appId]);

  const { success, applicationData, error } = useSelector(
    (state) => ({
      success: state.applications.success,
      applicationData: isEditing
        ? state.applications.data.find(
            (application) => application.id === appId
          )
        : undefined,
      error: state.applications.error,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing && !applicationData) {
      dispatch(fetchApplications(id));
    }
  }, [isEditing, id, applicationData]);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.APPLICATIONS.replace(':id', id)} />
  );

  const editApplicationMessage = useFormatMessage(
    'Application.editApplication'
  );

  const newApplicationMessage = useFormatMessage('Application.editApplication');

  const onSubmitHandler = useCallback(
    (applicationInfo) => {
      const newApplication = !isEditing
        ? {
            ...applicationInfo,
            isEditing,
            id,
            appId,
          }
        : {
            ...applicationInfo,
            appId,
          };

      if (isEditing) {
        dispatch(modifyApplication(newApplication));
      } else {
        dispatch(createApplication(newApplication));
      }
    },
    [isEditing, id]
  );

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editApplicationMessage : newApplicationMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !applicationData ? (
          <ClipLoader />
        ) : (
          <ApplicationForm
            isEditing={isEditing}
            application={
              isEditing
                ? applicationData
                : {
                    name: '',
                    domain: '',
                    type: '',
                    hasSMS: false,
                    integration: 'EasyPost',
                    integrationKey: '',
                    hasCustomLabels: false,
                    underMaintenance: false,
                    createdAt: new Date().toDateString(),
                    active: false,
                  }
            }
            onSubmitHandler={onSubmitHandler}
          />
        )}
      </section>
    </>
  );
};

export default Application;
