import { toastr } from 'react-redux-toastr';
import {
  LOGS_FETCH_DATA_INIT,
  LOGS_FETCH_DATA_SUCCESS,
  LOGS_FETCH_DATA_FAIL,
} from 'state/actionCreators/logs';
import { fetchCollection } from 'state/api';

export const fetchLogs = () => async (dispatch) => {
  dispatch(LOGS_FETCH_DATA_INIT());

  try {
    const merchants = await fetchCollection('merchants');
    const logs = await fetchCollection('logs');

    const merchantWithIdAsKey = [];
    merchants?.forEach((merchant) => {
      merchantWithIdAsKey[merchant?.id] = merchant;
    });

    const mappedLogs = logs
      .map((log) => ({
        ...log,
        name: merchantWithIdAsKey[log.merchantId].name,
      }));

    return dispatch(
      LOGS_FETCH_DATA_SUCCESS({
        data: mappedLogs,
      })
    );
  } catch (error) {
    toastr.error('Error fetching logs', error);
    return dispatch(LOGS_FETCH_DATA_FAIL({ error }));
  }
};
