import { useCallback } from 'react';
import ConfigForm from 'components/ConfigForm';
import { useFormatMessage } from 'hooks';
import { modifyCosts } from 'state/actions/costs';
import { useDispatch } from 'react-redux';

const Configuration = () => {
  const dispatch = useDispatch();

  const onSubmitHandler = useCallback((costs) => {
    dispatch(modifyCosts(costs));
  }, []);

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">{useFormatMessage('Configuration.home')}</h1>
        </div>
      </section>

      <section className="section is-main-section">
        {useFormatMessage('Configuration.content')}
        <ConfigForm onSubmitHandler={onSubmitHandler} />
      </section>
    </>
  );
};

export default Configuration;
