import { createReducer } from 'redux-act';

import {
  METRICS_FETCH_DATA_INIT,
  METRICS_FETCH_DATA_SUCCESS,
  METRICS_FETCH_DATA_FAIL,
} from 'state/actionCreators/metrics';

import { COSTS_MODIFY_COST_SUCCESS } from 'state/actionCreators/costs';

const initialState = {
  data: [],
  costs: {},
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const metricsReducer = createReducer(
  {
    [METRICS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [METRICS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data,
      costs: payload?.costs,
      loading: false,
      error: null,
    }),
    [METRICS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [COSTS_MODIFY_COST_SUCCESS]: (state, payload) => ({
      ...state,
      costs: payload?.costs,
      loading: false,
      error: null,
      success: true,
    }),
  },
  initialState
);
