import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactDatePicker from 'react-datepicker';
// eslint-disable-next-line
import TableToExcel from '@linways/table-to-excel';

import { useFormatMessage } from 'hooks';
import Table from 'components/Table';
import { merchantsCleanUp } from 'state/actions/merchants';
import paths from 'pages/Router/paths';
import { fetchMetrics } from 'state/actions/metrics';
import { formatCurrency } from 'utils';
import classes from './Metrics.module.scss';

const Metrics = () => {
  const { metricsList, isAdmin, error, loading } = useSelector(
    (state) => ({
      metricsList: state.metrics.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.metrics.error,
      loading: state.metrics.loading,
      deleted: state.metrics.deleted,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const handleClearDates = () => {
    setFrom('');
    setTo('');
  };

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchMetrics(from, to));
    }

    return () => dispatch(merchantsCleanUp());
  }, [isAdmin, from, to]);

  const redirect = !isAdmin && <Redirect to={paths.ROOT} />;

  const columns = [
    {
      Header: '',
      accessor: 'logoUrl',
      Cell: ({ row }) => (
        <div className="image">
          <img src={row.original.logoUrl} alt="" />
        </div>
      ),
      disableSortBy: true,
      ignoreReport: true,
    },
    {
      Header: useFormatMessage('Metrics.name'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('Metrics.sms'),
      accessor: 'sms',
      Cell: ({ row }) => (
        <div>
          {row.original.sms}{' '}
          {/*  TODO: add in future release
          {!row.original?.hasSMS && (
            <small style={{ color: 'red' }}>
              ({useFormatMessage('Metrics.disabled')})
            </small>
          )} */}
        </div>
      ),
    },
    {
      Header: useFormatMessage('Metrics.smsCost'),
      accessor: 'smsCost',
      Cell: ({ row }) => (
        <div>{formatCurrency.format(row.original.smsCost)}</div>
      ),
    },
    {
      Header: useFormatMessage('Metrics.emails'),
      accessor: 'emails',
    },
    {
      Header: useFormatMessage('Metrics.emailsCost'),
      accessor: 'emailsCost',
      Cell: ({ row }) => (
        <div>{formatCurrency.format(row.original.emailsCost)}</div>
      ),
    },
    {
      Header: useFormatMessage('Metrics.shipments'),
      accessor: 'shipments',
    },
    {
      Header: useFormatMessage('Metrics.shipmentsCost'),
      accessor: 'shipmentsCost',
      Cell: ({ row }) => (
        <div>{formatCurrency.format(row.original.shipmentsCost)}</div>
      ),
    },
    {
      Header: useFormatMessage('Metrics.labelsCost'),
      accessor: 'labelsCost',
      Cell: ({ row }) => (
        <div>{formatCurrency.format(row.original.labelsCost)}</div>
      ),
    },
    {
      Header: useFormatMessage('Metrics.totalCost'),
      accessor: 'totalCost',
      Cell: ({ row }) => (
        <div>{formatCurrency.format(row.original.totalCost)}</div>
      ),
    },
    /*     {
      Header: useFormatMessage('Metrics.firebase'),
      accessor: 'firebase',
      Cell: () => <>0</>,
    }, */
  ];

  const data = useMemo(
    () =>
      search
        ? metricsList.filter((el) => {
            const clonedElem = { ...el };
            delete clonedElem.id;
            delete clonedElem.isAdmin;
            delete clonedElem.logoUrl;
            return Object.values(clonedElem).some((field) =>
              field
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase())
            );
          })
        : metricsList,
    [search, metricsList]
  );

  const TABLE_ID = 'metrics_table';

  const tableOptions = {
    name:
      from || to
        ? `report-${from ? `from: ${from.toISOString().split('T')[0]}` : ''}-${
            to ? `to: ${to.toISOString().split('T')[0]}` : ''
          }.xlsx`
        : 'full-report-all-time.xlsx',
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">{useFormatMessage('Metrics.metrics')}</h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <button
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById(TABLE_ID),
                      tableOptions
                    );
                  }}
                >
                  Download Report
                </button>
              </div>
              <div className="level-item">
                <span>from:</span>
                <ReactDatePicker selected={from} onChange={(e) => setFrom(e)} />
              </div>
              <div className="level-item">
                <span>to:</span>
                <ReactDatePicker selected={to} onChange={(e) => setTo(e)} />
              </div>
              <div className="level-item">
                <button onClick={handleClearDates}>clear</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Merchants.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading && data.lenght > 0 ? (
              <ClipLoader />
            ) : (
              <Table id={TABLE_ID} columns={columns} data={data} />
            )}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
};

export default Metrics;
