import { createAction } from '@reduxjs/toolkit';

export const COSTS_MODIFY_COST_INIT = createAction(
  'COSTS_MODIFY_COST_INIT'
);
export const COSTS_MODIFY_COST_SUCCESS = createAction(
  'COSTS_MODIFY_COST_SUCCESS'
);
export const COSTS_MODIFY_COST_FAIL = createAction(
  'COSTS_MODIFY_COST_FAIL'
);
