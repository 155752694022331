/* eslint-disable jsx-a11y/label-has-associated-control */
import { useSelector, shallowEqual } from 'react-redux';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import { useFormatMessage } from 'hooks';
import ErrorMessage from 'components/ErrorMessage';
import { schema } from 'utils/schemas/costsFormSchema';
import { fetchMetrics } from 'state/actions/metrics';
import { formatCurrency } from 'utils';

import './ConfigForm.scss';

const ConfigForm = ({ onSubmitHandler }) => {
  const { loading, defaultValues } = useSelector(
    (state) => ({
      defaultValues: state.metrics.costs,
      loading: state.metrics.loading,
      success: state.metrics.success,
    }),
    shallowEqual
  );

  useEffect(() => {
    fetchMetrics();
  }, []);

  if (Object.keys(defaultValues).length === 0) {
    return null;
  }

  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: { ...defaultValues },
    resolver: yupResolver(schema),
  });

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                {useFormatMessage('ConfigurationForm.configInfo')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <input
                  name="id"
                  id="id"
                  className="input"
                  ref={register}
                  type="hidden"
                />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ConfigurationForm.emails')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="emails"
                          id="emails"
                          className="input"
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {errors.emails && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ConfigurationForm.sms')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="sms"
                          id="sms"
                          className="input"
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {errors.sms && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ConfigurationForm.shipments')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="shipments"
                          id="shipments"
                          className="input"
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {errors.shipments && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ConfigurationForm.label')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="labels"
                          id="labels"
                          className="input"
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {errors.label && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <hr />

                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>
                              {useFormatMessage('ApplicationForm.submit')}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account default" />
                </span>
                {useFormatMessage('ConfigurationForm.configPreview')}
              </p>
            </header>
            <div className="card-content">
              <div className="field">
                <label className="label">
                  {useFormatMessage('ConfigurationForm.emails')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="emails"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={formatCurrency.format(watch('emails') ?? 0)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('ConfigurationForm.sms')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="sms"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={formatCurrency.format(watch('sms') ?? 0)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('ConfigurationForm.shipments')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="shipments"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={formatCurrency.format(watch('shipments') ?? 0)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('ConfigurationForm.label')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="label"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={formatCurrency.format(watch('labels') ?? 0)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ConfigForm.propTypes = {
  onSubmitHandler: PropTypes.func.isRequired,
};

export default ConfigForm;
