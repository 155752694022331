import firebase from 'firebase';

import { isLocalEnv } from 'utils/env.helper';
import { getNewDocument } from 'state/api/firestore';
import { createDocument } from 'state/api';

export const checkMerchantsUniqueValues = async (db, { name }) => {
  const firestoreRef = db.collection('merchants');
  const merchantsWithSameName = await firestoreRef
    .where('name', '==', name)
    .get();

  if (merchantsWithSameName.size > 0) {
    const error = Error('Merchants.valuesMustBeUnique');
    error.code = 409;
    throw error;
  }
};

export const deleteLogo = (oldLogo) => {
  const logoPath = oldLogo
    .split('merchants%2F')
    .pop()
    .split('?alt=media')
    .shift();
  return firebase.storage().ref(`merchants/${logoPath}`).delete();
};

export const uploadLogo = (uid, file) => {
  const storageRef = firebase.storage().ref();
  const fileExtension = file.name.split('.').pop();
  const fileName = `${uid}.${fileExtension}`;
  return storageRef.child(`merchants/${fileName}`).put(file);
};

export const getLogoUrl = (uid, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/merchants%2F${uid}${
    isLocalEnv() ? '' : '_200x200'
  }.${fileExtension}?alt=media`;
};

const createMerchantDbTask = async (merchantData) => {
  const doc = await getNewDocument('merchants');

  const { name, url, secret, active, createdAt, file } = merchantData;

  let logoUrl = null;
  if (file) {
    logoUrl = await getLogoUrl(doc.id, merchantData.file);
    uploadLogo(doc.id, merchantData.file);
  }
  const newMerchantData = {
    name,
    url,
    secret,
    active,
    createdAt,
    logoUrl,
  };
  await createDocument(undefined, undefined, newMerchantData, doc);
  return newMerchantData;
};

export const createMerchantWithChecks = async ({
  name,
  url,
  secret,
  active,
  createdAt,
  file,
}) => {
  const db = firebase.firestore();
  const merchant = await db.runTransaction(async () => {
    await checkMerchantsUniqueValues(db, { name });

    return createMerchantDbTask({
      name,
      url,
      secret,
      active,
      createdAt,
      file,
    });
  });
  return merchant;
};
