import firebase from 'firebase';

export const updateConfigurationWithCheck = async ({
  emails,
  sms,
  shipments,
  id,
}) => {
  const db = firebase.firestore();
  const costData = {
    emails: Number(emails),
    sms: Number(sms),
    shipments: Number(shipments),
  };

  await db.runTransaction(async () => {
    const costsDoc = db.collection('costs').doc(id);
    await costsDoc.update(costData);
  });

  return costData;
};
