/* eslint-disable */
import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';

import { updateConfigurationWithCheck } from 'services/configurationService';
import {
  COSTS_MODIFY_COST_FAIL,
  COSTS_MODIFY_COST_INIT,
  COSTS_MODIFY_COST_SUCCESS,
} from 'state/actionCreators/costs';

export const modifyCosts = (modifiedCosts) => {
  return async (dispatch, getState) => {
    dispatch(COSTS_MODIFY_COST_INIT());
    const { locale } = getState().preferences;

    try {
      const costData = await updateConfigurationWithCheck(modifiedCosts);

      toastr.success('', 'Configuration updated successfully');

      return dispatch(
        COSTS_MODIFY_COST_SUCCESS({
          costs: { ...costData, id: modifiedCosts.id },
        })
      );
    } catch (error) {
      const errorMessage = firebaseError(error, locale);
      toastr.error('', errorMessage);
      return dispatch(
        COSTS_MODIFY_COST_FAIL({
          error: errorMessage,
        })
      );
    }
  };
};
