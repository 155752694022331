import { toastr } from 'react-redux-toastr';
import {
  METRICS_FETCH_DATA_INIT,
  METRICS_FETCH_DATA_SUCCESS,
  METRICS_FETCH_DATA_FAIL,
} from 'state/actionCreators/metrics';
import { fetchCollection } from 'state/api';

export const fetchMetrics = (dateFrom, dateTo) => async (dispatch) => {
  dispatch(METRICS_FETCH_DATA_INIT());

  try {
    const merchants = await fetchCollection('merchants');
    const metrics = await fetchCollection('metrics');
    const shipments = await fetchCollection('shipments');
    const costsReq = await fetchCollection('costs');
    const costs = costsReq?.[0];

    const merchantWithIdAsKey = [];
    merchants?.forEach((merchant) => {
      merchantWithIdAsKey[merchant?.id] = {
        ...merchant,
        sms: 0,
        emails: 0,
        shipments: 0,
        labels: 0,
        smsCost: 0,
        emailsCost: 0,
        shipmentsCost: 0,
        labelsCost: 0,
        totalCost: 0,
      };
    });

    shipments?.forEach((shipment) => {
      const shipmentDateTime = shipment.dateTime.split('T')[0];
      const validDateRange = (!dateFrom && !dateTo) || 
                             (dateFrom && dateFrom.toISOString().split('T')[0] <= shipmentDateTime && 
                              dateTo && dateTo.toISOString().split('T')[0] >= shipmentDateTime);
      if (validDateRange) {
        merchantWithIdAsKey[shipment.merchantId] = {
          ...merchantWithIdAsKey[shipment.merchantId],
          shipments: merchantWithIdAsKey[shipment.merchantId].shipments + 1,
          shipmentsCost: merchantWithIdAsKey[shipment.merchantId].shipmentsCost + costs.shipments,
          totalCost: merchantWithIdAsKey[shipment.merchantId].totalCost + costs.shipments,
        };
      }
    });

    metrics
      ?.filter((log) => {
        if (!dateFrom && !dateTo) {
          return true;
        }
        const logDateTime = log.dateTime.split('T')[0];
        if (!dateTo) {
          return dateFrom.toISOString().split('T')[0] <= logDateTime;
        }
        if (!dateFrom) {
          return dateTo.toISOString().split('T')[0] >= logDateTime;
        }
        return (
          dateFrom.toISOString().split('T')[0] <= logDateTime &&
          dateTo.toISOString().split('T')[0] >= logDateTime
        );
      })
      .forEach((metric) => {
        if (metric.type === 'SMS') {
          merchantWithIdAsKey[metric.merchantId] = {
            ...merchantWithIdAsKey[metric.merchantId],
            sms: merchantWithIdAsKey[metric.merchantId].sms + 1,
            smsCost: merchantWithIdAsKey[metric.merchantId].smsCost + costs.sms,
            totalCost:
              merchantWithIdAsKey[metric.merchantId].totalCost + costs.sms,
          };
        }
        if (metric.type === 'EMAIL') {
          merchantWithIdAsKey[metric.merchantId] = {
            ...merchantWithIdAsKey[metric.merchantId],
            emails: merchantWithIdAsKey[metric.merchantId].emails + 1,
            emailsCost:
              merchantWithIdAsKey[metric.merchantId].emailsCost + costs.emails,
            totalCost:
              merchantWithIdAsKey[metric.merchantId].totalCost + costs.emails,
          };
        }
        if (metric.type === 'SHIPMENT') {
          merchantWithIdAsKey[metric.merchantId] = {
            ...merchantWithIdAsKey[metric.merchantId],
            shipments: merchantWithIdAsKey[metric.merchantId].shipments + 1,
            shipmentsCost:
              merchantWithIdAsKey[metric.merchantId].shipmentsCost +
              costs.shipments,
            totalCost:
              merchantWithIdAsKey[metric.merchantId].totalCost +
              costs.shipments,
          };
        }
        if (metric.type === 'LABEL') {
          merchantWithIdAsKey[metric.merchantId] = {
            ...merchantWithIdAsKey[metric.merchantId],
            labels: merchantWithIdAsKey[metric.merchantId].labels + 1,
            labelsCost:
              merchantWithIdAsKey[metric.merchantId].labelsCost +
              costs.labels,
            totalCost:
              merchantWithIdAsKey[metric.merchantId].totalCost +
              costs.labels,
          };
        }
      });

      const arrayOfMetrics = Object.keys(merchantWithIdAsKey).map(
        (merchant) => merchantWithIdAsKey[merchant]
      );
      
    return dispatch(
      METRICS_FETCH_DATA_SUCCESS({
        costs,
        data: arrayOfMetrics,
      })
    );
  } catch (error) {
    toastr.error('Error fetching metrics', error);
    return dispatch(METRICS_FETCH_DATA_FAIL({ error }));
  }
};
