import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string().required(),

  active: yup.boolean().notRequired(),
  type: yup.string().required(),
  hasSMS: yup.boolean().notRequired(),
  integration: yup.string().notRequired(),
  integrationKey: yup.string().notRequired(),
  hasCustomLabels: yup.boolean().notRequired(),
  underMaintenance: yup.boolean().notRequired(),
  createdAt: yup.string().required(),
  domain: yup.string().when('type', {
    is: (type) => type === 'UnlockShippings',
    then: yup.string().required(),
  }),
  lookAndFeelId: yup.string().when('type', {
    is: (type) => type === 'UnlockShippings',
    then: yup.string().required(),
  }),
  netsuiteNotificationEndpoint: yup.string().when('type', {
    is: (type) => type === 'EasyPostIntegration',
    then: yup.string().required(),
  }),
  secret: yup.string().when('type', {
    is: (type) => type === 'EasyPostIntegration',
    then: yup.string().required(),
  }),
});
