import { createAction } from '@reduxjs/toolkit';

export const MERCHANTS_FETCH_DATA_INIT = createAction(
  'MERCHANTS_FETCH_DATA_INIT'
);
export const MERCHANTS_FETCH_DATA_SUCCESS = createAction(
  'MERCHANTS_FETCH_DATA_SUCCESS'
);
export const MERCHANTS_FETCH_DATA_FAIL = createAction(
  'MERCHANTS_FETCH_DATA_FAIL'
);

export const MERCHANTS_DELETE_MERCHANT_INIT = createAction(
  'MERCHANTS_DELETE_MERCHANT_INIT'
);
export const MERCHANTS_DELETE_MERCHANT_SUCCESS = createAction(
  'MERCHANTS_DELETE_MERCHANT_SUCCESS'
);
export const MERCHANTS_DELETE_MERCHANT_FAIL = createAction(
  'MERCHANTS_DELETE_MERCHANT_FAIL'
);

export const MERCHANTS_CREATE_MERCHANT_INIT = createAction(
  'MERCHANTS_CREATE_MERCHANT_INIT'
);
export const MERCHANTS_CREATE_MERCHANT_SUCCESS = createAction(
  'MERCHANTS_CREATE_MERCHANT_SUCCESS'
);
export const MERCHANTS_CREATE_MERCHANT_FAIL = createAction(
  'MERCHANTS_CREATE_MERCHANT_FAIL'
);

export const MERCHANTS_MODIFY_MERCHANT_INIT = createAction(
  'MERCHANTS_MODIFY_MERCHANT_INIT'
);
export const MERCHANTS_MODIFY_MERCHANT_SUCCESS = createAction(
  'MERCHANTS_MODIFY_MERCHANT_SUCCESS'
);
export const MERCHANTS_MODIFY_MERCHANT_FAIL = createAction(
  'MERCHANTS_MODIFY_MERCHANT_FAIL'
);

export const MERCHANTS_CLEAN_UP = createAction('MERCHANTS_CLEAN_UP');

export const MERCHANTS_CLEAR_DATA_LOGOUT = createAction(
  'MERCHANTS_CLEAR_DATA_LOGOUT'
);
