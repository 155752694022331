import { createAction } from '@reduxjs/toolkit';

export const APPLICATIONS_FETCH_DATA_INIT = createAction(
  'APPLICATIONS_FETCH_DATA_INIT'
);
export const APPLICATIONS_FETCH_DATA_SUCCESS = createAction(
  'APPLICATIONS_FETCH_DATA_SUCCESS'
);
export const APPLICATIONS_FETCH_DATA_FAIL = createAction(
  'APPLICATIONS_FETCH_DATA_FAIL'
);

export const APPLICATIONS_CREATE_APPLICATION_INIT = createAction(
  'APPLICATIONS_CREATE_APPLICATION_INIT'
);
export const APPLICATIONS_CREATE_APPLICATION_SUCCESS = createAction(
  'APPLICATIONS_CREATE_APPLICATION_SUCCESS'
);
export const APPLICATIONS_CREATE_APPLICATION_FAIL = createAction(
  'APPLICATIONS_CREATE_APPLICATION_FAIL'
);
export const APPLICATIONS_MODIFY_APPLICATION_INIT = createAction(
  'APPLICATIONS_MODIFY_APPLICATION_INIT'
);
export const APPLICATIONS_MODIFY_APPLICATION_SUCCESS = createAction(
  'APPLICATIONS_MODIFY_APPLICATION_SUCCESS'
);
export const APPLICATIONS_MODIFY_APPLICATION_FAIL = createAction(
  'APPLICATIONS_MODIFY_APPLICATION_FAIL'
);
