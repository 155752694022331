import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';
import { toastr } from 'react-redux-toastr';

import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import { fetchApplications } from 'state/actions/applications';
import paths from 'pages/Router/paths';
import classes from './Applications.module.scss';

const Applications = () => {
  const { applicationsList, isAdmin, error, loading } = useSelector(
    (state) => ({
      applicationsList: state.applications.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.applications.error,
      loading: state.applications.loading,
      deleted: state.applications.deleted,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { id } = useParams();

  const [search, setSearch] = useState('');
  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchApplications(id));
    }
  }, [dispatch, isAdmin]);

  const redirect = !isAdmin && <Redirect to={paths.ROOT} />;

  const SUCCESS_MESSAGE = useFormatMessage('Applications.clipboard');

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toastr.success('', SUCCESS_MESSAGE);
    } catch (err) {
      // eslint-disable-next-line
      console.error('Failed to copy: ', err);
    }
  };

  const columns = [
    {
      Header: useFormatMessage('Applications.name'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('Applications.domain'),
      accessor: 'domain',
    },
    {
      Header: useFormatMessage('Applications.type'),
      accessor: 'type',
    },
    {
      Header: useFormatMessage('Applications.hasSMS'),
      accessor: 'hasSMS',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {/* eslint-disable-next-line */}
          {row?.original?.type === 'EasyPostIntegration'
            ? '-'
            : row.original?.hasSMS
            ? useFormatMessage('Merchants.yes')
            : 'No'}
        </small>
      ),
    },
    {
      Header: useFormatMessage('Applications.hasCustomLabels'),
      accessor: 'hasCustomLabels',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {/* eslint-disable-next-line */}
          {row?.original?.type === 'EasyPostIntegration'
            ? '-'
            : row.original?.hasCustomLabels
            ? useFormatMessage('Merchants.yes')
            : 'No'}
        </small>
      ),
    },
    {
      Header: useFormatMessage('Applications.underMaintenance'),
      accessor: 'underMaintenance',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {/* eslint-disable-next-line */}
          {row?.original?.type === 'EasyPostIntegration'
            ? '-'
            : row.original?.underMaintenance
            ? useFormatMessage('Merchants.yes')
            : 'No'}
        </small>
      ),
    },
    {
      Header: useFormatMessage('Applications.productionKey'),
      accessor: 'productionKey',
      Cell: ({ row }) => (
        <small
          onClick={() => copyToClipboard(row?.original?.productionKey)}
          style={{ cursor: 'pointer' }}
          className="is-abbr-like"
        >
          {row?.original?.productionKey?.slice(0, 12)}...
        </small>
      ),
    },
    {
      Header: useFormatMessage('Applications.active'),
      accessor: 'active',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.active ? (
            <span className="icon">
              <i className="mdi mdi-check" />
            </span>
          ) : (
            <span className="icon">
              <i className="mdi mdi-close" />
            </span>
          )}
        </small>
      ),
    },
    {
      Header: useFormatMessage('Applications.created'),
      accessor: 'created',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {!row.original.isAdmin && (
            <div className="buttons is-right">
              <Link
                to={paths.MODIFY_APPLICATION.replace(':id', id).replace(
                  ':appId',
                  row.original.id
                )}
                className="button is-small is-primary"
              >
                <span className="icon is-small">
                  <i className="mdi mdi-account-edit" />
                </span>
              </Link>
            </div>
          )}
        </>
      ),
      disableSortBy: true,
    },
  ];

  const data = useMemo(
    () =>
      search
        ? applicationsList.filter((el) => {
            const clonedElem = { ...el };
            delete clonedElem.id;
            delete clonedElem.isAdmin;
            delete clonedElem.logoUrl;
            return Object.values(clonedElem).some((field) =>
              field
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase())
            );
          })
        : applicationsList,
    [search, applicationsList]
  );

  return (
    <>
      {redirect}

      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">
                  {useFormatMessage('Applications.applications')}
                </h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Link
                  to={paths.ADD_APPLICATION.replace(':id', id)}
                  className="button"
                >
                  {useFormatMessage('Applications.newApplication')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Applications.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading && data.lenght > 0 ? (
              <ClipLoader />
            ) : (
              <Table columns={columns} data={data} />
            )}
            {error && 'Show error'}
          </div>
        </div>
        <Link to={paths.MERCHANTS} className="button">
          {useFormatMessage('Applications.goBack')}
        </Link>
      </section>
    </>
  );
};

export default Applications;
