import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';

import { authReducer } from './auth';
import { usersReducer } from './users';
import { preferencesReducer } from './preferences';
import { merchantsReducer } from './merchants';
import { applicationsReducer } from './applications';
import { metricsReducer } from './metrics';
import { logsReducer } from './logsReducer';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading'],
    },
    authReducer
  ),
  preferences: persistReducer(
    { key: 'preferences', storage },
    preferencesReducer
  ),
  users: persistReducer(
    {
      key: 'users',
      storage,
      blacklist: ['error', 'loading'],
    },
    usersReducer
  ),
  merchants: persistReducer(
    {
      key: 'merchants',
      storage,
      blacklist: ['error', 'loading'],
    },
    merchantsReducer
  ),
  applications: persistReducer(
    {
      key: 'applications',
      storage,
      blacklist: ['error', 'loading'],
    },
    applicationsReducer
  ),
  toastr: toastrReducer,
  metrics: persistReducer(
    {
      key: 'metrics',
      storage,
      blacklist: ['error', 'loading'],
    },
    metricsReducer
  ),
  logs: persistReducer(
    {
      key: 'logs',
      storage,
      blacklist: ['error', 'loading'],
    },
    logsReducer
  ),
});
