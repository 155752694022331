/* eslint-disable jsx-a11y/label-has-associated-control */
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';

import paths from 'pages/Router/paths';
import { useFormatDate, useFormatMessage } from 'hooks';
import DatePicker from 'components/DatePicker';
import ErrorMessage from 'components/ErrorMessage';
import { schema } from 'utils/schemas/applicationFormSchema';

import './ApplicationForm.scss';

const UnlockShippings = 'UnlockShippings';
const UnlockReturns = 'UnlockReturns';
const easyPostIntegration = 'EasyPostIntegration';

const EasyPost = 'EasyPost';
const ShipHawk = 'ShipHawk';

const isUnlockProduct = (type) =>
  [UnlockShippings, UnlockReturns].includes(type);

const ApplicationForm = ({
  isEditing,
  isProfile,
  application,
  onSubmitHandler,
}) => {
  const { loading } = useSelector(
    (state) => ({
      loading: state.applications.loading,
      success: state.applications.success,
    }),
    shallowEqual
  );

  const { register, handleSubmit, errors, control, watch } = useForm({
    defaultValues: { ...application },
    resolver: yupResolver(schema),
  });

  const goBackMessage = useFormatMessage('ApplicationForm.goBack');
  const activeMessage = useFormatMessage('ApplicationForm.active');

  const { id } = useParams();

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                {useFormatMessage('ApplicationForm.applicationInfo')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                {isEditing ? (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {useFormatMessage('ApplicationForm.name')}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            name="name"
                            id="name"
                            className="input"
                            ref={register}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {useFormatMessage('ApplicationForm.name')}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            name="name"
                            id="name"
                            className={classNames('input', {
                              'is-danger': errors.name,
                            })}
                            ref={register}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {errors.name && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ApplicationForm.type')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <select
                          name="type"
                          id="type"
                          className={classNames('select', {
                            'is-danger': errors.type,
                          })}
                          ref={register}
                        >
                          <option value={UnlockShippings}>
                            {UnlockShippings}
                          </option>
                          <option value={easyPostIntegration}>
                            {easyPostIntegration}
                          </option>
                          <option value={UnlockReturns}>{UnlockReturns}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {errors.type && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                {watch('type') === UnlockShippings && (
                  <>
                    <div className="field has-check is-horizontal">
                      <div className="field-label">
                        <label className="label">
                          {useFormatMessage('ApplicationForm.SMS')}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="field">
                            <div className="control">
                              <label className="b-checkbox checkbox">
                                <input
                                  type="checkbox"
                                  name="hasSMS"
                                  ref={register}
                                />
                                <span className="check is-primary" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.hasSMS && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                  </>
                )}

                {watch('type') === UnlockReturns && (
                  <>
                    <div className="field has-check is-horizontal">
                      <div className="field-label">
                        <label className="label">
                          {useFormatMessage('ApplicationForm.hasCustomLabels')}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="field">
                            <div className="control">
                              <label className="b-checkbox checkbox">
                                <input
                                  type="checkbox"
                                  name="hasCustomLabels"
                                  ref={register}
                                />
                                <span className="check is-primary" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.hasCustomLabels && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                  </>
                )}
                {watch('type') === UnlockReturns && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {useFormatMessage('ApplicationForm.integration')}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <select
                            name="integration"
                            id="integration"
                            className={classNames('select', {
                              'is-danger': errors.type,
                            })}
                            ref={register}
                          >
                            <option value={EasyPost}>{EasyPost}</option>
                            <option value={ShipHawk}>{ShipHawk}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {watch('type') === UnlockReturns && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {useFormatMessage('ApplicationForm.integrationKey')}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            className={classNames(`input`, {
                              'is-danger': errors.domain,
                            })}
                            ref={register}
                            name="integrationKey"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isUnlockProduct(watch('type')) && (
                  <>
                    <div className="field has-check is-horizontal">
                      <div className="field-label">
                        <label className="label">
                          {useFormatMessage('ApplicationForm.underMaintenance')}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="field">
                            <div className="control">
                              <label className="b-checkbox checkbox">
                                <input
                                  type="checkbox"
                                  name="underMaintenance"
                                  ref={register}
                                />
                                <span className="check is-primary" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.underMaintenance && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="field has-check is-horizontal">
                  <div className="field-label">
                    <label className="label">{activeMessage}</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="field">
                        <div className="control">
                          <label className="b-checkbox checkbox">
                            <input
                              type="checkbox"
                              name="active"
                              ref={register}
                            />
                            <span className="check is-primary" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('ApplicationForm.created')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <Controller
                        control={control}
                        name="createdAt"
                        render={({ onChange, name, value }) => (
                          <DatePicker
                            name={name}
                            onChange={onChange}
                            date={new Date(value)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                {isUnlockProduct(watch('type')) && (
                  <>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage('ApplicationForm.domain')}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className={classNames(`input`, {
                                'is-danger': errors.domain,
                              })}
                              ref={register}
                              name="domain"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.domain && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage('ApplicationForm.lookAndFeelId')}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="lookAndFeelId"
                              id="lookAndFeelId"
                              className={classNames('input', {
                                'is-danger': errors.lookAndFeelId,
                              })}
                              ref={register}
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.lookAndFeelId && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                  </>
                )}

                {watch('type') === easyPostIntegration && (
                  <>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage(
                            'ApplicationForm.netsuiteNotificationEndpoint'
                          )}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="netsuiteNotificationEndpoint"
                              id="netsuiteNotificationEndpoint"
                              className={classNames('input', {
                                'is-danger':
                                  errors.netsuiteNotificationEndpoint,
                              })}
                              ref={register}
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.netsuiteNotificationEndpoint && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage('ApplicationForm.secret')}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="secret"
                              id="secret"
                              className={classNames('input', {
                                'is-danger': errors.secret,
                              })}
                              ref={register}
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.secret && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage('Applications.productionKey')}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="productionKey"
                              id="productionKey"
                              className={classNames('input', {
                                'is-danger': errors.productionKey,
                              })}
                              ref={register}
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.productionKey && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                  </>
                )}

                <hr />

                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>
                              {useFormatMessage('ApplicationForm.submit')}
                            </span>
                          </button>
                        </div>
                        {!isProfile && (
                          <Link
                            to={paths.APPLICATIONS.replace(':id', id)}
                            className="button"
                          >
                            {goBackMessage}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account default" />
                </span>
                {useFormatMessage('ApplicationForm.applicationPreview')}
              </p>
            </header>
            <div className="card-content">
              <div className="field">
                <label className="label">
                  {useFormatMessage('ApplicationForm.name')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="name"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('name')}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('ApplicationForm.type')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="Type"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('type')}
                  />
                </div>
              </div>
              {isUnlockProduct(watch('type')) && (
                <div className="field">
                  <label className="label">
                    {useFormatMessage('ApplicationForm.SMS')}
                  </label>
                  <div className="control is-clearfix" data-testid="hasSMS">
                    {watch('hasSMS') ? (
                      <span className="icon">
                        <i className="mdi mdi-check" />
                      </span>
                    ) : (
                      <span className="icon">
                        <i className="mdi mdi-close" />
                      </span>
                    )}
                  </div>
                </div>
              )}

              {isUnlockProduct(watch('type')) && (
                <div className="field">
                  <label className="label">
                    {useFormatMessage('ApplicationForm.underMaintenance')}
                  </label>
                  <div
                    className="control is-clearfix"
                    data-testid="underMaintenance"
                  >
                    {watch('underMaintenance') ? (
                      <span className="icon">
                        <i className="mdi mdi-check" />
                      </span>
                    ) : (
                      <span className="icon">
                        <i className="mdi mdi-close" />
                      </span>
                    )}
                  </div>
                </div>
              )}

                {isUnlockProduct(watch('integration')) && (
                <div className="field">
                  <label className="label">
                    {useFormatMessage('ApplicationForm.integration')}
                  </label>
                  <div
                    className="control is-clearfix"
                    data-testid="integration"
                  >
                    {watch('integration') ? (
                      <span className="icon">
                        <i className="mdi mdi-check" />
                      </span>
                    ) : (
                      <span className="icon">
                        <i className="mdi mdi-close" />
                      </span>
                    )}
                  </div>
                </div>
              )}

                {isUnlockProduct(watch('integrationKey')) && (
                <div className="field">
                  <label className="label">
                    {useFormatMessage('ApplicationForm.integrationKey')}
                  </label>
                  <div
                    className="control is-clearfix"
                    data-testid="integrationKey"
                  >
                    {watch('integrationKey') ? (
                      <span className="icon">
                        <i className="mdi mdi-check" />
                      </span>
                    ) : (
                      <span className="icon">
                        <i className="mdi mdi-close" />
                      </span>
                    )}
                  </div>
                </div>
              )}

              {!isProfile && (
                <div className="field">
                  <label className="label">{activeMessage}</label>
                  <div className="control is-clearfix" data-testid="active">
                    {watch('active') ? (
                      <span className="icon">
                        <i className="mdi mdi-check" />
                      </span>
                    ) : (
                      <span className="icon">
                        <i className="mdi mdi-close" />
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div className="field">
                <label className="label">
                  {useFormatMessage('ApplicationForm.created')}
                </label>
                <div className="control is-clearfix" data-testid="date">
                  <p className="date">
                    {useFormatDate(watch('createdAt'), {
                      weekday: 'short',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </p>
                </div>
              </div>

              {isUnlockProduct(watch('type')) && (
                <>
                  <div className="field">
                    <label className="label">
                      {useFormatMessage('ApplicationForm.domain')}
                    </label>
                    <div className="control is-clearfix">
                      <input
                        data-testid="domain"
                        type="text"
                        readOnly="readOnly"
                        className="input is-static"
                        value={watch('domain')}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">
                      {useFormatMessage('ApplicationForm.lookAndFeelId')}
                    </label>
                    <div className="control is-clearfix">
                      <input
                        data-testid="Look and Feel id"
                        type="text"
                        readOnly="readOnly"
                        className="input is-static"
                        value={watch('lookAndFeelId')}
                      />
                    </div>
                  </div>
                </>
              )}

              {watch('type') === easyPostIntegration && (
                <>
                  <div className="field">
                    <label className="label">
                      {useFormatMessage(
                        'ApplicationForm.netsuiteNotificationEndpoint'
                      )}
                    </label>
                    <div className="control is-clearfix">
                      <input
                        data-testid="Type"
                        type="text"
                        readOnly="readOnly"
                        className="input is-static"
                        value={watch('netsuiteNotificationEndpoint')}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">
                      {useFormatMessage('ApplicationForm.secret')}
                    </label>
                    <div className="control is-clearfix">
                      <input
                        data-testid="Type"
                        type="text"
                        readOnly="readOnly"
                        className="input is-static"
                        value={watch('secret')}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">
                      {useFormatMessage('Applications.productionKey')}
                    </label>
                    <div className="control">
                      <input
                        data-testid="name"
                        type="text"
                        readOnly="readOnly"
                        className="input is-static"
                        value={watch('productionKey')}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ApplicationForm.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.string,
    active: PropTypes.bool,
    name: PropTypes.string,
    domain: PropTypes.string,
    createdAt: PropTypes.string,
    type: PropTypes.string,
    lookAndFeelId: PropTypes.string,
    netsuiteNotificationEndpoint: PropTypes.string,
    secret: PropTypes.string,
    productionKey: PropTypes.string,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  isEditing: PropTypes.bool,
  isProfile: PropTypes.bool,
};

ApplicationForm.defaultProps = {
  isEditing: false,
  isProfile: false,
};

export default ApplicationForm;
