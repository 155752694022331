import { createReducer } from 'redux-act';

import {
  APPLICATIONS_FETCH_DATA_INIT,
  APPLICATIONS_FETCH_DATA_SUCCESS,
  APPLICATIONS_FETCH_DATA_FAIL,
  APPLICATIONS_CREATE_APPLICATION_INIT,
  APPLICATIONS_CREATE_APPLICATION_SUCCESS,
  APPLICATIONS_CREATE_APPLICATION_FAIL,
  APPLICATIONS_MODIFY_APPLICATION_FAIL,
  APPLICATIONS_MODIFY_APPLICATION_INIT,
  APPLICATIONS_MODIFY_APPLICATION_SUCCESS,
} from 'state/actionCreators/applications';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const applicationsReducer = createReducer(
  {
    [APPLICATIONS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [APPLICATIONS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data,
      loading: false,
      error: null,
    }),
    [APPLICATIONS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [APPLICATIONS_CREATE_APPLICATION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [APPLICATIONS_CREATE_APPLICATION_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload.application),
      loading: false,
      error: null,
      success: true,
    }),
    [APPLICATIONS_CREATE_APPLICATION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [APPLICATIONS_MODIFY_APPLICATION_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [APPLICATIONS_MODIFY_APPLICATION_SUCCESS]: (state, payload) => ({
      ...state,
      data: !state.data
        ? []
        : state.data.map((elem) => {
            if (elem.id === payload.id) {
              return {
                type: payload.application.type,
                domain: payload.application.domain,
                active: payload.active,
                lookAndFeelId: payload.application.lookAndFeelId,
                createdAt: payload.application.createdAt,
                name: elem.name,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
    }),
    [APPLICATIONS_MODIFY_APPLICATION_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
